<template>
    <div :class="{dark:isBgDark}" class="navigation-slider-inner z toggle-to-dark">
        <div class="swiper-container container navigation-slider">
            <div class="swiper-wrapper">
                <div v-for="(item, index) in slides" @click="changeRoute(item)" :key="index" class="swiper-slide">
                    <div class="swiper-slide-item-inner">
                        <div :style="{ background: item.color }" class="slide-item-background"></div>
                        <div class="content">
                            <span class="title luxury">{{ item.title }}</span>
                            <div @click="changeRoute(item)" class="explore-inner cta-m" data-cursor-type="hide">
                                <svg class="ev-none" width="27" height="12" viewBox="0 0 27 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <line x1="0.5" y1="5.63281" x2="25.5" y2="5.63281" stroke="white"
                                          stroke-linecap="round"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M22.2246 0.351569L26.7899 5.10469C27.07 5.39636 27.07 5.86926 26.7899 6.16094L22.2246 10.9141C21.9445 11.2057 21.4903 11.2057 21.2101 10.9141C20.93 10.6224 20.93 10.1495 21.2101 9.85781L25.2681 5.63281L21.2101 1.40782C20.93 1.11614 20.93 0.643244 21.2101 0.351569C21.4903 0.0598937 21.9445 0.0598937 22.2246 0.351569Z"
                                          fill="white"/>
                                </svg>
                                <span class="description fira" data-cursor-type="hide">{{ item.urlTitle }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide-item-down">
                        <picture>
                            <source media="(max-width:767px)" :srcset="item.mobile"/>
                            <source media="(max-width:1023px)" :srcset="item.tablet"/>
                            <img class="slide-image" :src="item.desktop"/>
                        </picture>
                        <div class="down-linear"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useMenuStore} from "@/store/pinia/menu/index";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {onMounted, computed} from "vue";
import gsap from 'gsap'
import Swiper from "swiper";
import {useRouter} from "vue-router";

let router = useRouter()
let store = useMenuStore()
let scroller = store.Loco
const props = defineProps({
    contentData: {
        type: Object,
    },
});
const onScroll = (pageContainer) => {
    let pinWrap = document.querySelector(".pin-wrap");
    let pinWrapWidth = pinWrap.offsetWidth;
    let horizontalScrollLength = pinWrapWidth - window.innerWidth;
    // Pinning and horizontal scrolling

    gsap.to(".pin-wrap", {
        scrollTrigger: {
            scroller: "[data-scroll-container]", //locomotive-scroll
            scrub: true,
            trigger: "#sectionPin",
            pin: true,
            // anticipatePin: 1,
            start: "top top",
            end: pinWrapWidth
        },
        x: -horizontalScrollLength,
        ease: "none"
    });

    ScrollTrigger.addEventListener("refresh", () => scroller.update()); //locomotive-scroll


    ScrollTrigger.refresh();
}
const setScroll = () => {
    gsap.registerPlugin(ScrollTrigger);

    const pageContainer = document.querySelector('[data-scroll-container]');

    scroller.on("scroll", ScrollTrigger.update);

    ScrollTrigger.scrollerProxy(pageContainer, {
        scrollTop(value) {
            return arguments.length
                ? scroller.scrollTo(value, 0, 0)
                : scroller.scroll.instance.scroll.y;
        },
        getBoundingClientRect() {
            return {
                left: 0,
                top: 0,
                width: window.innerWidth,
                height: window.innerHeight
            };
        },
        pinType: pageContainer.style.transform ? "transform" : "fixed"
    });
    onScroll(pageContainer)
}
const changeRoute = (item) => {
    router.push({path: `/${store.locale}/${item.link}`})
}
let isBgDark = computed(() => store.isBgDark)
let slides = computed(() => props.contentData.data.list?.map(item => ({
    ...item,
    mobile: item.images?.[0]?.devices?.mobile,
    desktop: item.images?.[0]?.devices?.desktop,
    tablet: item.images?.[0]?.devices?.tablet,
    color: item.colors?.bgColor,
    urlTitle: item.url?.title,
    link: item.url?.url,
})))
const setSwiper = () => {
    let swiper = new Swiper('.navigation-slider', {
        touchRatio: 2.5,
        grabCursor: true,
        spaceBetween: 20,
        speed: 1200,
        slidesPerView: 1.3,
        breakpoints: {
            1025: {
                spaceBetween: 21,
                speed: 1200,
                slidesPerView: 3.1,
            },
            767: {
                spaceBetween: 21,
                speed: 1200,
                slidesPerView: 2.1,
            }
        },
    });
}

onMounted(() => {
    setSwiper()
})
</script>

<style lang="scss" scoped>
.navigation-slider-inner {
    padding-top: 144px;
    background: white;
    transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1) all;

    &.dark {
        background: #111929;
    }

    @media only screen and (max-width: 1440px) {
        padding-top: 55px;
    }

    .swiper-wrapper {
        will-change: transform;
    }

    .swiper-slide {
        height: 610px;
        position: relative;
        overflow: hidden;

        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            height: 500px;
        }

        @media only screen and (max-width: 1439px) and (min-width: 1025px) {
            height: 400px;
        }

        @media only screen and (max-width: 1024px) and (min-width: 768px) {
            height: 350px;
        }

        @media only screen and (max-width: 767px) {
            height: 255px;
        }

        &:hover {
            .slide-item-background {
                transform: translateY(100%) rotate(-3deg) !important;
                transition: all 1s cubic-bezier(.13, -0.01, .18, .99), opacity .1ms 1s ease !important;
                opacity: 0 !important;
            }
        }

        .swiper-slide-item-down {
            width: 100%;
            height: 100%;
            position: relative;

            .down-linear {
                position: absolute;
                background: linear-gradient(180deg, #182236 0%, rgba(24, 34, 54, 0) 100%);
                width: 100%;
                height: 40%;
                pointer-events: none;
                bottom: 0;
                transform: rotate(180deg);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

        }

        .content {
            color: white;
            position: absolute;
            bottom: 74px;
            left: 60px;

            @media only screen and (max-width: 1650px) and (min-width: 1025px) {
                left: 30px;
            }

            @media only screen and (max-width: 1024px) {
                left: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .title {
                font-style: normal;
                font-weight: 400;
                font-size: 55px;
                text-transform: uppercase;

                @media only screen and (max-width: 1650px) {
                    font-size: 34px;
                }
                @media only screen and (max-width: 1024px) {
                    font-size: 28px !important;
                    text-align: center;
                }

            }

            .explore-inner {
                display: flex;
                align-items: center;
                margin-top: 16px;

                @media only screen and (max-width: 1024px) {
                    display: none;
                }

                .description {
                    font-weight: 400;
                    font-size: 21px;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    margin-left: 8px;

                    @media only screen and (max-width: 1650px) {
                        font-size: 18px;
                    }
                }
            }
        }

        .swiper-slide-item-inner {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            background: transparent;

            .slide-item-background {
                pointer-events: none;
                position: absolute;
                background: #8C8C8C;
                width: calc(100% + 100px);
                height: calc(100% + 100px);
                opacity: 1;
                left: -50px;
                top: -50px;
                transform: translateY(0) rotate(-3deg);
                transition: transform .45s ease, opacity 1s cubic-bezier(.13, -0.01, .18, .99);
                transform-origin: bottom;
                @media only screen and (max-width: 1024px) {
                    display: none;
                }

            }

            .title {
                @media only screen and (max-width: 1024px) {
                    font-size: 34px;
                }

            }

        }
    }
}

.test {
    #sectionPin {
        height: 100vh;
        overflow: hidden;
        display: flex;
        left: 0;
    }

    img {
        height: 80vh;
        width: auto;
        object-fit: cover;
    }

    .pin-wrap {
        height: 100vh;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        & > * {
            min-width: 60vw;
            padding: 0 5vw;
        }
    }
}
</style>

<style lang="scss">
body {
    &.is-dark {
        .navigation-slider-inner {
            background: #111929 !important;
        }
    }
}

</style>
